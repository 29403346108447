import { Controller } from "@hotwired/stimulus"
import fullpage from 'fullpage.js/dist/fullpage.extensions'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    // eslint-disable-next-line
    this.fullpage = new fullpage('#fullpage', {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.fullpage.destroy()
    this.fullpage = undefined
  }

  get defaultOptions () {
    return {
      licenseKey: '144K9-8M467-NAKHJ-J9YQH-QZUKO',
      css3: true,
      scrollingSpeed: 700,
      easing: 'easeInOutCubic',
      easingcss3: 'ease',
      keyboardScrolling: true
    }
  }
}
